<template>
  <div>
    <router-view :key="$route.path" />
    <Notification />
    <NotificationDialog />
  </div>
</template>
<script>
import Notification from '@/components/Notification'
import NotificationDialog from '@/components/NotificationDialog'
import { usersStart, usersStop } from '@/signalr/users'
import { start, stop } from '@/signalr/chat'
import Messenger from '@/utils/messenger.js'

export default {
  name: 'ChatLayout',
  components: {
    Notification,
    NotificationDialog
  },
  data () {
    return {}
  },
  mounted () {
    Messenger.initChild(window)
  },
  beforeCreate () {
    usersStart()
    start()
  },
  created () {
    const recaptcha = this.$recaptchaInstance
    if (recaptcha) { recaptcha.hideBadge() }
  },
  beforeDestroy () {
    usersStop()
    stop()
  },
  methods: {}
}
</script>
